import { useAppContext } from '@components/app-context'
import axios from 'axios'
import { BigNumber } from 'ethers'
import { parseUnits } from 'ethers/lib/utils'
import { checkIsEth } from 'helpers/utils'
import { useMemo } from 'react'
import { QueryFunction, useQuery, UseQueryResult } from 'react-query'
import useBlockNumber from './useBlockNumber'

interface FeeData {
	maxPriorityFee: number
	maxFee: number
}

interface FeeDataInBigNumber {
	maxPriorityFee: BigNumber
	maxFee: BigNumber
}

export interface GasStation {
	safeLow: FeeData
	standard: FeeData
	fast: FeeData
	estimatedBaseFee: number
	blockTime: number
	blockNumber: number
}

interface Config {
	/**
	 * Sets the gas calculation for the transaction to go through
	 * Defaults to fast
	 */
	gasFeeMode?: 'fast' | 'safeLow' | 'standard'

	/**
	 * Returns 125% increased gas fees
	 */
	getIncreasedValue?: boolean

	/**
	 * Watches the network for change of block
	 */
	watch?: boolean

	onSuccess?: (result: FeeDataInBigNumber) => void
}

const makeQueryKey = ({
	chainId,
	gasFeeMode,
	getIncreasedValue,
	watch,
	blockNumber,
}: Config & { chainId: number; blockNumber: number }) =>
	[
		{
			entity: 'usePolygonGasDetails',
			chainId,
			getIncreasedValue,
			gasFeeMode,
			watch,
			blockNumber,
		},
	] as const

const getPolygonGasDetails: QueryFunction<FeeDataInBigNumber, ReturnType<typeof makeQueryKey>> = async ({
	queryKey: [{ chainId, gasFeeMode, getIncreasedValue }],
}) => {
	const GAS_STATION_URL =
		chainId === 137 ? 'https://gasstation-mainnet.matic.network/v2' : 'https://gasstation-mumbai.matic.today/v2'

	const gasDetails = await axios.get<GasStation>(GAS_STATION_URL).then((res) => res.data)

	let maxFee = parseUnits(Math.ceil(gasDetails[gasFeeMode].maxFee).toString(), 'gwei')
	let maxPriorityFee = parseUnits(Math.ceil(gasDetails[gasFeeMode].maxPriorityFee).toString(), 'gwei')

	if (getIncreasedValue) {
		maxFee = maxFee.mul('125').div('100')
		maxPriorityFee = maxPriorityFee.mul('125').div('100')
	}

	return {
		maxFee,
		maxPriorityFee,
	}
}

/**
 *
 * @param gasFeeMode and getIncreasedValue
 * @returns maxFee and maxPriorityFee in Gwei along with UseQueryResult
 */
const usePolygonGasDetails = ({
	gasFeeMode = 'fast',
	getIncreasedValue,
	watch,
	onSuccess,
}: Config): UseQueryResult<FeeDataInBigNumber> => {
	const { chainId } = useAppContext()
	const { data: blockNumber } = useBlockNumber({ watch })

	const queryKey = useMemo(
		() => makeQueryKey({ blockNumber, chainId, gasFeeMode, getIncreasedValue, watch, onSuccess }),
		[blockNumber, chainId, gasFeeMode, getIncreasedValue, watch, onSuccess]
	)

	return useQuery(queryKey, getPolygonGasDetails, {
		enabled: !checkIsEth(chainId), // only run if polygon network
		cacheTime: 0,
		onSuccess,
		keepPreviousData: true,
	})
}

export default usePolygonGasDetails
