import { useEffect } from 'react'
import { useQuery, useQueryClient, UseQueryResult } from 'react-query'
import useProvider, { ProviderType } from './useProvider'

interface Config {
	watch?: boolean
}

interface UseBlockNumber {
	(config: Config): UseQueryResult<number>
}

const useBlockNumber: UseBlockNumber = ({ watch } = {}) => {
	const queryClient = useQueryClient()

	const provider = useProvider(ProviderType.ReadOnly)
	const websocketProvider = useProvider(ProviderType.ReadOnlyWebsocket)

	const query = useQuery('block-number', () => provider.getBlockNumber(), {
		enabled: Boolean(provider),
		retry: false,
		staleTime: 0,

		// TODO: recheck these values
		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
	})

	useEffect(() => {
		if (!websocketProvider || !watch) return

		const listener = (blockNumber: number): void => {
			queryClient.setQueryData('block-number', blockNumber)
		}

		websocketProvider.on('block', listener)

		return () => {
			websocketProvider.off('block', listener)
		}
	}, [websocketProvider, watch, queryClient])

	return query
}

export default useBlockNumber
