import { useAppContext } from '@components/app-context'
import { SafeMultisigTransactionResponse } from '@gnosis.pm/safe-service-client'
import { useEffect, useState } from 'react'
import { useQuery, UseQueryResult } from 'react-query'

interface GnosisTransactionHook {
	query: UseQueryResult<SafeMultisigTransactionResponse>
	setSafeTxHash: (safeTxHash: string) => void
}

interface UseGnosisTransactionOptions {
	onSuccess?: (safeTxResponse: SafeMultisigTransactionResponse) => void | Promise<void>
}

const useGnosisTransaction = ({ onSuccess }: UseGnosisTransactionOptions = {}): GnosisTransactionHook => {
	const { safeService } = useAppContext()
	const [safeTxHash, setSafeTxHash] = useState('')
	const [isEnabled, setIsEnabled] = useState(true)

	const query = useQuery(['gnosis-tx', safeTxHash], () => safeService.getTransaction(safeTxHash), {
		enabled: Boolean(safeService && safeTxHash && isEnabled),

		refetchInterval: (data) => (data && !data.isExecuted ? 10000 : false),
		staleTime: Infinity,
		cacheTime: Infinity,
	})

	useEffect(() => {
		if (query.data && query.data.isExecuted && isEnabled) {
			setIsEnabled(false)

			if (onSuccess) {
				try {
					onSuccess(query.data)
				} catch (error) {
					console.warn('Failed to execute onSuccess callback', error)
				}
			}
		}
	}, [query.data, isEnabled])

	return {
		query,
		setSafeTxHash,
	}
}

export default useGnosisTransaction
