import { useAppContext } from '@components/app-context'
import { updateToast } from '@components/Toasts'
import { TransactionReceipt, TransactionResponse } from '@ethersproject/providers'
import { SafeMultisigTransactionResponse } from '@gnosis.pm/safe-service-client'
import * as Sentry from '@sentry/nextjs'
import { BigNumber, CallOverrides, ethers } from 'ethers'
import { LogDescription } from 'ethers/lib/utils'
import { Chain } from 'helpers/chain'
import { supportMessage } from 'helpers/constants'
import { getTypedMessage } from 'helpers/gaslessTx'
import { ContractName } from 'helpers/utils'
import { ReactText, useCallback, useEffect, useReducer, useState } from 'react'
import { GetContractTypeEnum } from 'utils/getContract'
import useGnosisTransaction from '../query/useGnosisTransaction'
import { useContract, UseContractConfig } from '../useContract'
import useCurrentUser from '../useCurrentUser'
import usePolygonGasDetails from '../usePolygonGasDetails'
import { useWaitForTransaction } from '../useWaitForTransaction'
import useBiconomy from './useBiconomy'

interface FunctionConfig {
	/** Arguments to pass contract method */
	args?: any[]
	overrides?: CallOverrides

	toastId?: ReactText
}

interface UseContractWriteConfig {
	gnosis?: boolean | 'auto'
	successMessage?: string
	failedMessage?: string
	loadingMessage?: string

	/** Configuration related to gassless transaction through biconomy */
	gaslessTx?: {
		enabled: boolean
		chainId?: number
		contractName: ContractName
		contractVersion: string
		useUpdatedTypedMessage?: boolean
	}
	onSuccess?: (
		tx: SafeMultisigTransactionResponse | TransactionReceipt,
		parsedLogs?: LogDescription[]
	) => void | Promise<void>
	parseLogs?: boolean

	onSend?: (txHash: string) => void | Promise<void>
	onError?: (tx?: SafeMultisigTransactionResponse | TransactionReceipt) => void | Promise<void>
	showToast?: boolean
}

interface State {
	loading: boolean
	isExecuted: boolean
	isSuccessful: boolean | null
	isError: boolean | null
	error?: any
}

const initialState: State = {
	loading: false,
	isExecuted: false,
	isSuccessful: null,
	isError: null,
}

interface WriteCallback {
	(fnName: string, config?: FunctionConfig): Promise<void>
}

type UseContractWriteResponse = readonly [
	Omit<State, 'txResponse'> & {
		canWrite: boolean
		data: {
			txReceipt?: TransactionReceipt
			safeTxResponse?: SafeMultisigTransactionResponse
		}
	},
	WriteCallback
]

const useContractWrite = <Contract extends ethers.Contract>(
	contractConfig: Omit<UseContractConfig, 'type'>,
	{
		gnosis = 'auto',
		successMessage,
		loadingMessage,
		failedMessage,
		onSuccess,
		onSend,
		onError,
		parseLogs: parseLogs = false,
		showToast = true,
		gaslessTx: gaslessTx,
	}: UseContractWriteConfig = {}
): UseContractWriteResponse => {
	const { safeSdk, safeService, walletProvider, chainId } = useAppContext()
	const { data: user } = useCurrentUser()

	// TODO:
	const shouldUseGnosis = gnosis === 'auto' ? Boolean(safeSdk) : gnosis

	/** Contract to interact with */
	const contract = useContract<Contract>({ ...contractConfig, type: GetContractTypeEnum.ReadAndWrite })

	/** Polls gnosis for tx confirmation */
	const {
		query: { data },
		setSafeTxHash,
	} = useGnosisTransaction({
		onSuccess: async (safeTxResponse) => {
			setState((x) => ({ ...x, loading: false, isExecuted: true, isSuccessful: true }))
			try {
				if (onSuccess) {
					if (parseLogs) {
						const txReceipt = await walletProvider.getTransactionReceipt(safeTxResponse.transactionHash)
						const parsedLogs = txReceipt.logs
							.map((log) => {
								try {
									return contract.interface.parseLog(log)
								} catch (error) {
									return null
								}
							})
							.filter(Boolean)
						await onSuccess(safeTxResponse, parsedLogs)
					} else {
						await onSuccess(safeTxResponse)
					}
				}
			} catch (error) {
				console.warn('failed on to run onsuccess', error)
			}
			if (showToast) {
				updateToast({
					toastId,
					title: successMessage ?? 'Transaction successful',
					hash: safeTxResponse.transactionHash,
					progress: 1,
				})
			}
		},
	})

	const [txHash, setTxHash] = useState('')

	const [dependOnPolygonGas, stopDependingOnPolygonGas] = useReducer(() => false, true)
	const { data: polygonGasFees } = usePolygonGasDetails({
		gasFeeMode: 'fast',
		getIncreasedValue: true,
		watch: !txHash,
		onSuccess: () => stopDependingOnPolygonGas(),
	})

	/** Polls blockchain for tx mined confirmation */
	const [{ receipt }] = useWaitForTransaction({
		hash: txHash,
		onSuccess: async (_receipt) => {
			try {
				if (onSuccess) {
					if (parseLogs) {
						const parsedLogs = _receipt.logs
							.map((log) => {
								try {
									return contract.interface.parseLog(log)
								} catch (error) {
									return null
								}
							})
							.filter(Boolean)
						await onSuccess(_receipt, parsedLogs)
					} else {
						await onSuccess(_receipt)
					}
				}
			} catch (error) {
				console.warn('failed on to run onsuccess', error)
			}
			setState((x) => ({ ...x, loading: false, isExecuted: true, isSuccessful: true }))
			if (showToast) {
				updateToast({
					toastId,
					title: successMessage ?? 'Transaction successful',
					hash: _receipt.transactionHash,
					progress: 1,
				})
			}
		},
		onError: async (...params) => {
			setState((x) => ({ ...x, loading: false, isExecuted: true, isSuccessful: false, isError: true }))
			try {
				await onError(...params)
			} catch (error) {
				//
			}
		},
	})

	const [state, setState] = useState<State>(initialState)
	const [toastId, setToastId] = useState<ReactText>('')

	const currentChain = new Chain(chainId)
	const isPolygon = currentChain.isPolygon()

	const { biconomy, isLoading: isBiconomyLoading } = useBiconomy(gaslessTx)

	const canWrite =
		!!contract &&
		(!isPolygon || !dependOnPolygonGas ? true : !!polygonGasFees) &&
		(gaslessTx?.enabled ? isBiconomyLoading : true)

	const write = useCallback<WriteCallback>(
		async (functionName, { args = [], overrides: _overrides, toastId: _toastId } = {}) => {
			if (!user) {
				updateToast({ toastId: _toastId, body: 'Please login to continue', type: 'warning' })
				return
			}

			if (!contract || (isPolygon ? !polygonGasFees : false)) {
				// ideally the below is never run
				updateToast({ toastId: _toastId, body: 'Please wait for a few seconds', type: 'warning' })
				return
			}

			const overrides: CallOverrides = isPolygon
				? { maxFeePerGas: polygonGasFees.maxFee, maxPriorityFeePerGas: polygonGasFees.maxPriorityFee, ..._overrides }
				: _overrides

			// contract function params
			const params = [...args, ...(overrides ? [overrides] : [])]
			Sentry.addBreadcrumb({
				data: {
					functionName,
					params,
				},
				category: 'contract',
				level: Sentry.Severity.Info,
			})

			setToastId(_toastId)

			if (shouldUseGnosis) {
				// TODO:
				if (!safeSdk || !safeService) throw new Error('Safe SDK or service is not set')

				setState((x) => ({ ...x, loading: true }))

				try {
					// Create gnosis tx
					const safeTx = await safeSdk.createTransaction({
						to: ethers.utils.getAddress(contract.address),
						data: contract.interface.encodeFunctionData(functionName, args),
						value: _overrides?.value ? _overrides.value.toString() : '0',
					})
					await safeSdk.signTransaction(safeTx)
					const safeTxHash = await safeSdk.getTransactionHash(safeTx)

					// Send tx to gnosis for further confirmations and eventual execution
					await safeService.proposeTransaction({
						safeAddress: safeSdk.getAddress(),
						safeTransaction: safeTx,
						safeTxHash,
						senderAddress: user.publicAddress,
					})

					// Setting this starts polling for tx execution confirmation by `useGnosisTransaction`
					setSafeTxHash(safeTxHash)

					updateToast({
						toastId: _toastId,
						title: loadingMessage,
						body: 'Please execute transaction on your Gnosis Safe',
						progress: 0.8,
					})
					if (onSend) {
						await onSend(safeTxHash)
					}
				} catch (error) {
					// TODO: Remove after updating Sentry play
					console.log('error', error)
					Sentry.captureException(error)
					setState((x) => ({ ...x, loading: false }))
					updateToast({
						toastId: _toastId,
						title: 'Failed to send transaction',
						body: supportMessage,
						type: 'error',
					})
				}
			} else {
				try {
					setState((x) => ({ ...x, loading: true }))

					let response: TransactionResponse

					if (gaslessTx?.enabled) {
						const contractWithBiconomy = contract.connect(biconomy.getSignerByAddress(user.publicAddress))
						const userNonce: string = await contractWithBiconomy
							.getNonce(user.publicAddress)
							.then((nonce: BigNumber) => nonce.toString())
						const functionSignature = contractWithBiconomy.interface.encodeFunctionData(functionName, args)
						const typedMessage = getTypedMessage(
							{
								name: gaslessTx.contractName,
								version: gaslessTx.contractVersion,
								nonce: userNonce,
								address: user.publicAddress,
								functionSignature,
								contractAddress: contractConfig.addressOrName,
								chainId: gaslessTx.chainId ?? chainId,
							},
							gaslessTx?.useUpdatedTypedMessage
						)

						updateToast({
							toastId: _toastId,
							title: 'Please sign the message sent to your wallet',
							progress: 0.6,
						})
						const signature = (await walletProvider.send('eth_signTypedData_v4', [
							user.publicAddress,
							JSON.stringify(typedMessage),
						])) as string

						const { r, s, v } = ethers.utils.splitSignature(signature)

						response = (await contractWithBiconomy.executeMetaTransaction(
							user.publicAddress,
							functionSignature,
							r,
							s,
							v,
							{ ..._overrides }
						)) as TransactionResponse
					} else {
						updateToast({
							toastId: _toastId,
							title: 'Please sign the transaction sent to your wallet',
							progress: 0.6,
						})

						if (!contract[functionName]) {
							updateToast({
								toastId: _toastId,
								title: 'No function available in the contract',
								type: 'error',
							})
							return
						}
						response = (await contract[functionName](...params)) as TransactionResponse
					}

					// Setting this starts polling for tx confirmation by `useWaitTransaction`
					setTxHash(response.hash)
					if (onSend) {
						await onSend(response?.hash)
					}

					if (showToast) {
						updateToast({
							toastId: _toastId,
							title: loadingMessage ?? 'Transaction in progress...',
							hash: response.hash,
							progress: 0.8,
						})
					}
				} catch (error) {
					setState((x) => ({ ...x, loading: false }))
					onError && onError()
					if (error?.code === 4001) {
						// user denied signature
						updateToast({
							toastId: _toastId,
							title: 'Transaction was rejected',
							type: 'warning',
						})
						// } else if (error?.code === -32603) {
						// 	//! mostly its "insufficient funds" but not everytime sometimes
						// 	updateToast({
						// 		toastId: _toastId,
						// 		title: 'Insufficient funds to pay gas',
						// 		body: 'If issue persists, please ' + supportMessage,
						// 		type: 'warning',
						// 	})
						// 	console.log('error', error)
						// 	Sentry.captureException(error)
					} else {
						// TODO: Remove after updating Sentry play
						Sentry.captureException(error)
						updateToast({
							toastId: _toastId,
							title: 'Failed to send transaction',
							body: supportMessage,
							type: 'error',
						})
					}
				}
			}
		},
		[
			user,
			contract,
			isPolygon,
			polygonGasFees,
			shouldUseGnosis,
			safeSdk,
			safeService,
			setSafeTxHash,
			loadingMessage,
			onSend,
			gaslessTx,
			showToast,
			biconomy,
			contractConfig.addressOrName,
			chainId,
			walletProvider,
			onError,
		]
	)

	/**
	 * For: Gnosis Safe
	 * Effect runs on safe tx data change and changes state accordingly
	 */
	useEffect(() => {
		if (!data) return

		if (data.isExecuted) {
			if (data.isSuccessful) {
				//
			} else {
				// TODO:
				setState((x) => ({ ...x, loading: false, isExecuted: true, isSuccessful: false }))
				if (showToast) {
					updateToast({
						toastId,
						title: failedMessage ?? 'Transaction failed',
						hash: data.transactionHash,
						type: 'error',
					})
				}
				onError && onError()
			}
		}
	}, [data, failedMessage, onError, successMessage, toastId, showToast])

	return [
		{
			...state,
			canWrite,
			data: {
				txReceipt: receipt,
				safeTxResponse: data,
			},
		},
		write,
	] as const
}

export default useContractWrite
