import { ethers } from 'ethers'
import { ContractName } from './utils'

/**
 * The `NonEIP712Domain` is used for contracts with meta-tx that were enabled before
 * domain used proper EIP712 specification
 * @see {@link https://github.com/bcnmy/metatx-standard/commit/6843edcce3d2dfada55f3d93e1be1d0faa1131a6| EIP712 Spec Commit}
 */
type NonEIP712Domain = {
	name: string
	version: string
	/** chainId in decimal number */
	salt: number
	verifyingContract: string
}

/**
 * @see {@link https://eips.ethereum.org/EIPS/eip-712| EIP712 Spec}
 */
type EIP712Domain = {
	name: string
	version: string
	/** chainId padded with 0s to make it bytes32 */
	salt: string
	verifyingContract: string
}
type Domain = NonEIP712Domain | EIP712Domain

type TypedMessage = {
	domain: Domain
	primaryType: string
	types: { EIP712Domain: { name: string; type: string }[]; MetaTransaction: { name: string; type: string }[] }
	message: { nonce: number; from: string; functionSignature: string }
}

type GetTypedMessageParams = {
	name: ContractName
	version: string
	nonce: string
	address: string
	functionSignature: string
	contractAddress: string
	chainId: number
}

/**
 *
 * @param {boolean} useEIP712Domain If `true` will use the correct EIP712 domain
 *
 * @returns TypedMessage to be signed by the wallet to send a meta (gasless) transaction via Biconomy
 */
export const getTypedMessage = (
	{ name, version, nonce, address, functionSignature, contractAddress, chainId }: GetTypedMessageParams,
	useEIP712Domain?: boolean
): TypedMessage => {
	const domain: Domain = useEIP712Domain
		? {
				name,
				version,
				verifyingContract: contractAddress,
				salt: ethers.utils.hexZeroPad(ethers.BigNumber.from(chainId).toHexString(), 32),
		  }
		: {
				name,
				version,
				salt: chainId,
				verifyingContract: contractAddress,
		  }

	return {
		domain,
		primaryType: 'MetaTransaction',
		types: {
			EIP712Domain: useEIP712Domain
				? [
						{ name: 'name', type: 'string' },
						{ name: 'version', type: 'string' },
						{ name: 'verifyingContract', type: 'address' },
						{ name: 'salt', type: 'bytes32' },
				  ]
				: [
						{ name: 'name', type: 'string' },
						{ name: 'version', type: 'string' },
						{ name: 'salt', type: 'uint256' },
						{ name: 'verifyingContract', type: 'address' },
				  ],
			MetaTransaction: [
				{ name: 'nonce', type: 'uint256' },
				{ name: 'from', type: 'address' },
				{ name: 'functionSignature', type: 'bytes' },
			],
		},
		message: {
			nonce: parseInt(nonce),
			from: address,
			functionSignature,
		},
	}
}
