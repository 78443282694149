import { TransactionReceipt, TransactionResponse } from '@ethersproject/providers'
import { useCallback, useEffect, useState } from 'react'
import { useCancel } from './useCancel'
import useProvider, { ProviderType } from './useProvider'

export type Config = {
	/**
	 * Number of blocks to wait for after transaction is mined
	 * @default 1
	 */
	confirmations?: number
	/** Transaction hash to monitor */
	hash?: string
	/** Disables fetching */
	skip?: boolean
	/**
	 * Maximum amount of time to wait before timing out in milliseconds
	 * @default 0
	 */
	timeout?: number
	/** Function resolving to transaction receipt */
	wait?: TransactionResponse['wait']

	/** Callbacks */
	onSuccess?: (receipt: TransactionReceipt) => void | Promise<void>
	onError?: (receipt: TransactionReceipt) => void | Promise<void>
}

type State = {
	receipt?: TransactionReceipt
	error?: any
	loading: boolean
}

const initialState: State = {
	loading: false,
}

type WaitConfig = Omit<Config, 'skip'>

export const useWaitForTransaction = ({
	confirmations,
	hash,
	skip,
	timeout,
	wait: wait_,
	onSuccess,
	onError,
}: Config = {}): readonly [State, (config: WaitConfig) => Promise<Omit<State, 'loading'>>] => {
	const provider = useProvider(ProviderType.ReadOnly)
	const [state, setState] = useState<State>(initialState)

	const cancelQuery = useCancel()

	const wait = useCallback(
		async (_config?: WaitConfig) => {
			let didCancel = false
			cancelQuery(() => {
				didCancel = true
			})

			try {
				const config = _config ?? { confirmations, hash, timeout, wait: wait_ }
				if (!config.hash && !config.wait) throw new Error('hash or wait is required')

				let promise: Promise<TransactionReceipt>

				if (config.wait) promise = config.wait(config.confirmations)
				else if (config.hash) promise = provider.waitForTransaction(config.hash, config.confirmations, config.timeout)

				setState((x) => ({ ...x, loading: true }))
				const receipt = await promise
				// chec receipt.status for failed tx and use onError
				if (!didCancel) {
					setState((x) => ({ ...x, loading: false, receipt }))
				}

				if (receipt.status === 1) {
					if (onSuccess) {
						try {
							await onSuccess(receipt)
						} catch (error) {
							console.warn('failed on to run onSuccess', error)
						}
					}
				} else {
					if (onError) {
						try {
							await onError(receipt)
						} catch (error) {
							console.warn('failed on to run onError', error)
						}
					}
				}

				return { receipt, error: undefined }
			} catch (error) {
				if (!didCancel) {
					setState((x) => ({ ...x, error, loading: false }))
				}
				return { receipt: undefined, error }
			}
		},
		[cancelQuery, confirmations, hash, onError, onSuccess, provider, timeout, wait_]
	)

	// Fetch balance when deps or chain changes
	// TODO:
	useEffect(() => {
		if (skip || (!hash && !wait_) || state.receipt) return
		wait({ confirmations, hash, timeout, wait: wait_ })

		return cancelQuery

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cancelQuery, hash, skip, wait_])

	return [state, wait] as const
}
