import { Biconomy } from '@biconomy/mexa'
import { useAppContext } from '@components/app-context'
import useProvider, { ProviderType } from '@hooks/useProvider'
import * as Sentry from '@sentry/nextjs'
import { isProduction } from 'helpers/constants'
import { biconKeyList } from 'helpers/utils'
import { useEffect, useMemo, useState } from 'react'

type Config = {
	enabled?: boolean
	chainId?: number
}

type UseBiconomyResult = {
	biconomy: Biconomy | null
	isLoading: boolean
	error: any | null
}

const useBiconomy = ({ chainId, enabled }: Config = {}): UseBiconomyResult => {
	const { chainId: activeChainId } = useAppContext()
	const chainIdToUse = chainId ?? activeChainId

	const [isLoading, setIsLoading] = useState(true)
	const [error, setError] = useState<any>(null)

	const provider = useProvider(ProviderType.ReadOnly, chainIdToUse)

	const biconomy = useMemo<Biconomy>(() => {
		if (!provider) return null
		const biconomyKey = biconKeyList[chainIdToUse]
		if (!biconomyKey) {
			console.warn('Biconomy key not found')
			return null
		}

		return new Biconomy(provider, {
			apiKey: biconomyKey,
			debug: !isProduction,
		})
	}, [chainIdToUse, provider])

	useEffect(() => {
		if (!enabled || !biconomy) return

		biconomy
			.onEvent(biconomy.READY, () => {
				setIsLoading(false)
			})
			.onEvent(biconomy.ERROR, (error, message) => {
				setError(error)
				Sentry.captureException(error, (scope) => scope.setExtras({ message }))
			})
	}, [biconomy, enabled])

	return {
		biconomy,
		isLoading,
		error,
	}
}

export default useBiconomy
